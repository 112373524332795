import type { AxiosRequestConfig } from 'nordic-axios';

import { RestClient } from 'nordic/restclient';

import api from '../../constants/api.json';
import { singletonRequest } from './interceptors/singleton-request';
import { trackErrorTraceClient } from './interceptors/track-error-trace-client';

export type ExtendedConfig = AxiosRequestConfig & {
  singletonId?: string;
};

const { REST_CLIENT, BASE_URL } = api;

const baseRestClient = RestClient({
  baseURL: BASE_URL,
  timeout: REST_CLIENT.TIMEOUT,
});

baseRestClient.client.interceptors.request.use(singletonRequest);
baseRestClient.client.interceptors.response.use(
  undefined,
  trackErrorTraceClient,
);

export const restClient = {
  async get<T = unknown>(url: string, config?: ExtendedConfig) {
    return baseRestClient.get<T>(url, config);
  },

  async post<T = unknown>(url: string, config?: ExtendedConfig) {
    return baseRestClient.post<T>(url, config);
  },

  async put<T = unknown>(url: string, config?: ExtendedConfig) {
    return baseRestClient.put<T>(url, config);
  },

  async patch<T = unknown>(url: string, config?: ExtendedConfig) {
    return baseRestClient.patch<T>(url, config);
  },

  async delete<T = unknown>(url: string, config?: ExtendedConfig) {
    return baseRestClient.delete<T>(url, config);
  },
};
