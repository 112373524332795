import type { Route } from 'frontend-spa';

import qs from 'qs';

import { Utils } from '../../../utils';
import pages from '../../../constants/pages.json';
import api from '../../../constants/api.json';
import { restClient } from '../../../common/rest-client';
import { ErrorPageContainer } from '../../../container/ErrorContainer/ErrorPageContainer';
import { QueryParams } from '../../../utils/query-params';
import { spaRoute } from '../../../common/spa-route';
import commons from '../../../constants/commons.json';

const { FEED_LIMIT_DESKTOP } = commons;
const { SEARCH_PAGE_LANG, SEARCH_PAGE } = pages;
const { PATH_API } = api;

export const SearchResultRoutes: Array<Route> = Utils.getArrayValuesFromObject({
  oldRoute: SEARCH_PAGE,
  ...SEARCH_PAGE_LANG,
}).map((path) =>
  spaRoute({
    path: `${path}/:keyword`,
    exact: true,
    async resolve() {
      return import(
        /* webpackChunkName: "search-results-desktop.chunk" */ './view'
      )
        .then((module) => module.default)
        .catch((error: unknown) => () => <ErrorPageContainer error={error} />);
    },
    webpack() {
      return require.resolveWeak('./view');
    },
    async fetchData(params) {
      const query = qs.stringify({
        keyword: params.keyword,
        offset: 0,
        limit: FEED_LIMIT_DESKTOP,
      });

      const { data } = await restClient.get(
        `/${PATH_API.SEARCH_RESULTS}?${query}`,
        {
          singletonId: 'spa-navigation',
          params: QueryParams.filterParamsWithCustomPrefix(
            window.location.href,
          ),
        },
      );

      return data;
    },
  }),
);
