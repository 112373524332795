import type { AxiosResponse, AxiosError } from 'nordic-axios';
import type { InnerRequestConfig } from '../create-rest-client';

export const getTags = (
  config: InnerRequestConfig,
  response?: AxiosResponse,
): Record<string, string> => {
  const httpMethod = response?.config.method ?? 'unknown';
  const httpStatus = response?.status.toString() ?? 'unknown';
  const httpStatusClass =
    httpStatus === 'unknown' ? 'unknown' : `${httpStatus[0]}XX`;

  return {
    ...config.tags,
    httpMethod,
    httpStatus,
    httpStatusClass,
    client: config.clientName,
    action: config.actionName,
  };
};

export const getTagsClient = (
  error: AxiosError,
): Record<string, number | string> => {
  const code = error.response?.status ?? 'unknown';
  const url = error.config.url ?? 'unknown';
  const method = error.config.method ?? 'unknown';

  return {
    code,
    url,
    method,
  };
};
