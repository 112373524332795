import type { Route } from 'frontend-spa';

import { transformDesktopComponents } from '../../../utils/transform-desktop-components';
import { getErrorView } from './get-error-view';
import { isView } from './is-view';

export const partialViewFetchData =
  (fetchData: Route['fetchData']): Route['fetchData'] =>
  async (params, state, history) => {
    try {
      if (isView(state)) {
        const viewPromise = state.isPartialView
          ? fetchData?.(params, state, history)
          : undefined;

        return {
          view: {
            ...state,
            components: transformDesktopComponents(state.components),
            viewPromise,
          },
        };
      }

      const view = await fetchData?.(params, state, history);

      return { view };
    } catch (error: unknown) {
      return {
        view: getErrorView(error),
      };
    }
  };
