import type { Route } from 'frontend-spa';
import type { DesktopComponents, ViewProps } from '../../../components/Page';

import pages from '../../../constants/pages.json';
import api from '../../../constants/api.json';
import { Utils } from '../../../utils';
import { restClient } from '../../../common/rest-client';
import { Pages } from '../../../utils/utils/pages';
import { QueryParams } from '../../../utils/query-params';
import { spaRoute } from '../../../common/spa-route';

const { VCP_PAGE_LANG, VCP_PAGE } = pages;
const { PATH_API } = api;

export const baseRoute: Omit<Route, 'path'> = spaRoute({
  exact: true,
  async resolve() {
    return import(/* webpackChunkName: "vcp-desktop.chunk" */ './view')
      .then(Pages.preloadHubAndVcpPages)
      .catch(Pages.errorPageContainer);
  },
  webpack() {
    return require.resolveWeak('./view');
  },
  async fetchData(params) {
    const { data } = await restClient.get<ViewProps<DesktopComponents>>(
      `/${PATH_API.VCP}/${params.content_id}`,
      {
        singletonId: 'spa-navigation',
        params: QueryParams.filterParamsWithCustomPrefix(window.location.href),
      },
    );

    return data;
  },
});

export const VCPRoutes: Array<Route> = Utils.getArrayValuesFromObject({
  oldRoute: VCP_PAGE,
  ...VCP_PAGE_LANG,
}).map((path) => ({
  path: `${path}/:content_name?/:content_id`,
  ...baseRoute,
}));
