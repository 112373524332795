import type {
  DesktopComponents,
  MobileComponents,
  ViewProps,
} from '../../../../app/components/Page';

import { CustomError } from '../../../../services/utils/custom-error/custom-error';
import { ErrorType } from './custom-api-error.types';

export class CustomApiError extends CustomError {
  public name = 'CustomApiError';

  public errorType: ErrorType;

  constructor(
    readonly error: unknown,
    errorType: ErrorType = ErrorType.DEFAULT,
  ) {
    super(error);
    this.errorType = errorType;
  }

  public getApiError<
    T extends MobileComponents | Partial<DesktopComponents> = MobileComponents,
  >(device?: string): ViewProps<T> | unknown {
    if (this.errorType === ErrorType.PAGE) {
      return super.getError(device);
    }

    return this.error;
  }
}
