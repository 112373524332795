import type { ImageProps } from '../../../../components/Image/mobile';

import { useContext } from 'react';

import { i18nContext } from '../../../../context';
import { Image as ImageBase } from '../../../../components/Image/mobile';

export const Image = ({ title, ...props }: ImageProps) => {
  const { get } = useContext(i18nContext) || props.i18nProps;
  return <ImageBase title={get(title)} {...props} />;
};
