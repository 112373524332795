import type { AxiosError } from 'axios';
import type {
  DesktopComponents,
  MobileComponents,
  ViewProps,
} from '../../../app/components/Page';
import type {
  KnownComponent,
  KnownComponentsMap,
} from '../../../types/components';

import axios from 'axios';

import { middleendNotAvailable, unknownError, forbidden } from './known-errors';
import {
  ErrorCode,
  ApplicationError,
  ExternalErrorCode,
} from './custom-error.types';

export const mapErrors = new Map<ErrorCode, ViewProps>([
  [ErrorCode.MIDDLEEND_NOT_AVAILABLE, middleendNotAvailable as ViewProps],
  [ErrorCode.UNKNOWN_ERROR, unknownError as ViewProps],
  [ErrorCode.FORBIDDEN, forbidden as ViewProps],
]);

type ErrorResponse = {
  errorCode: number;
  message: string;
  name: string;
};

const getResponseAxiosError = (error: AxiosError) => {
  const { response, message, code } = error;

  const errorsCode: Record<number | string, ErrorResponse> = {
    [ExternalErrorCode.SERVICE_UNAVAILABLE]: {
      errorCode: ErrorCode.MIDDLEEND_NOT_AVAILABLE,
      message: response?.statusText ?? '',
      name: ApplicationError.SERVER_ERROR,
    },
    [ExternalErrorCode.FORBIDDEN]: {
      errorCode: ErrorCode.FORBIDDEN,
      message: response?.statusText ?? '',
      name: ApplicationError.SERVER_ERROR,
    },
    [ExternalErrorCode.NETWORK_ERROR_MESSAGE]: {
      errorCode: ErrorCode.UNKNOWN_ERROR,
      message,
      name: ApplicationError.INTERNET_ERROR,
    },
    [ExternalErrorCode.NETWORK_ERROR_CODE]: {
      errorCode: ErrorCode.UNKNOWN_ERROR,
      message,
      name: ApplicationError.INTERNET_ERROR,
    },
    [ExternalErrorCode.TIMEOUT_ERROR]: {
      errorCode: ErrorCode.UNKNOWN_ERROR,
      message,
      name: ApplicationError.INTERNET_ERROR,
    },
    [ExternalErrorCode.UNKNOWN_ERROR]: {
      errorCode: ErrorCode.UNKNOWN_ERROR,
      message,
      name: ApplicationError.SERVER_ERROR,
    },
  };

  return (
    errorsCode[response?.status ?? code ?? message] ??
    errorsCode[ExternalErrorCode.UNKNOWN_ERROR]
  );
};

const getResponseUnknownError = (message: string) => {
  const errorObj: Record<string, ErrorResponse> = {
    INTERNET_ERROR: {
      errorCode: ErrorCode.UNKNOWN_ERROR,
      name: ApplicationError.INTERNET_ERROR,
      message,
    },
    SERVER_ERROR: {
      errorCode: ErrorCode.INTERNAL_SERVER_ERROR,
      name: ApplicationError.SERVER_ERROR,
      message,
    },
  };

  return errorObj[message];
};

export class CustomError extends Error {
  public errorCode: ErrorCode = ErrorCode.UNKNOWN_ERROR;

  public error: unknown;

  public status?: number;

  constructor(error: unknown) {
    super();
    this.error = error;
    this.initError(error);
  }

  private initError(error: unknown) {
    if (this.isAxiosError(error)) {
      this.getAxiosError(error as AxiosError);
    } else {
      const resUnknownError = getResponseUnknownError(
        ApplicationError.SERVER_ERROR,
      );

      this.errorCode = resUnknownError.errorCode;
      this.message = resUnknownError.message;
      this.name = resUnknownError.name;
    }
  }

  private getAxiosError(error: AxiosError) {
    const axiosError = getResponseAxiosError(error);

    if (axiosError) {
      this.status = error.response?.status;
      this.errorCode = axiosError.errorCode;
      this.message = axiosError.message;
      this.name = axiosError.name;
    } else {
      const resUnknownError = getResponseUnknownError(
        ApplicationError.SERVER_ERROR,
      );

      this.errorCode = resUnknownError.errorCode;
      this.message = resUnknownError.message;
      this.name = resUnknownError.name;
    }
  }

  private isAxiosError(error: unknown) {
    return axios.isAxiosError(error);
  }

  public transformDesktopComponents(
    components: Array<KnownComponent> = [],
  ): Partial<KnownComponentsMap> {
    return components.reduce(
      (currentValue, { type, props, ...otherProps }) => ({
        ...currentValue,
        [type]: { ...props, ...otherProps },
      }),
      {},
    );
  }

  public getError<
    T extends DesktopComponents | MobileComponents = MobileComponents,
  >(device = 'mobile'): ViewProps<T> {
    const errorMap =
      mapErrors.get(this.errorCode) ??
      mapErrors.get(ErrorCode.MIDDLEEND_NOT_AVAILABLE);

    return device === 'mobile'
      ? (errorMap as ViewProps<T>)
      : ({
          ...errorMap,
          components: this.transformDesktopComponents(errorMap?.components),
        } as ViewProps<T>);
  }
}
