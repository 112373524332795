import type { Context } from 'react';
import type { StaticDataProviderProps } from './static-data-context.types';

import { createContext, useContext } from 'react';

const defaultContext: StaticDataProviderProps = {
  version: null,
  VCMMaxHeight: null,
  device: null,
  scope: undefined,
};

export const StaticDataContext: Context<StaticDataProviderProps> =
  createContext<StaticDataProviderProps>(defaultContext);

export const { Provider: StaticDataProvider } = StaticDataContext;

export const useStaticData = () => useContext(StaticDataContext);
