import type { CancelTokenSource } from 'nordic-axios';
import type { ExtendedConfig } from '../rest-client';

import axios from 'nordic-axios';

const cancelTokens = new Map<string, CancelTokenSource>();

export const singletonRequest = (config: ExtendedConfig) => {
  if (!config.singletonId) {
    return config;
  }

  const cancelToken = axios.CancelToken.source();

  cancelTokens.get(config.singletonId)?.cancel();
  cancelTokens.set(config.singletonId, cancelToken);
  config.cancelToken = cancelToken.token;

  return config;
};
