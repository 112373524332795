import type { FC } from 'react';
import type { DesktopComponents } from '../../components/Page';

import { I18nProvider } from '../../context';
import { TypeKnownComponent } from '../../../types/components';
import { CustomErrorPages } from '../../utils/custom-error/pages';
import { ErrorContainer } from './ErrorContainer';

export const ErrorPageContainer: FC<{ error: unknown }> = ({ error }) => {
  const pageResult = new CustomErrorPages(
    error,
  ).getComponentsPageError<DesktopComponents>('desktop');

  if (!pageResult.components[TypeKnownComponent.ERROR]) {
    return null;
  }

  return (
    <I18nProvider>
      <ErrorContainer
        className="mplay-error"
        components={pageResult.components[TypeKnownComponent.ERROR].components}
      />
    </I18nProvider>
  );
};
