export enum ErrorCode {
  MIDDLEEND_NOT_AVAILABLE,
  NOT_ALLOWED_DEVICE,
  MUST_UPDATE_DEVICE,
  UNKNOWN_ERROR,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  DOWNLOAD_APP_MOBILE,
  SPARKLE_SEARCH,
  DOWNLOAD_APP_TABLET,
}

export enum ExternalErrorCode {
  SERVICE_UNAVAILABLE = 503,
  FORBIDDEN = 403,
  NETWORK_ERROR_MESSAGE = 'Network Error',
  NETWORK_ERROR_CODE = 'ENOTFOUND',
  TIMEOUT_ERROR = 'ECONNABORTED',
  UNKNOWN_ERROR = 'unknown',
}

export enum ApplicationError {
  SERVER_ERROR = 'SERVER_ERROR',
  INTERNET_ERROR = 'INTERNET_ERROR',
}
