import type { ButtonContractProps } from '../../../../components/Button/Button.type';

import { ButtonRefresh } from '../ButtonRefresh';
import { ButtonGoTo } from '../ButtonGoTo';

export const Button = ({ action, ...props }: ButtonContractProps) => {
  switch (action?.type) {
    case 'refresh':
      return <ButtonRefresh {...props} />;
    case 'go_to':
      return <ButtonGoTo {...props} />;

    default:
      return null;
  }
};
