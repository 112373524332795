import type { FC } from 'react';
import type { DesktopComponents, PageProps } from '../../components/Page';

import { useEffect } from 'react';

import { ErrorPageContainer } from '../../container/ErrorContainer/ErrorPageContainer';

type Module =
  | typeof import('../../pages/hub/desktop/view')
  | typeof import('../../pages/VCP/desktop/view');

export namespace Pages {
  export const preloadHubAndVcpPages = (
    module: Module,
  ): FC<PageProps<DesktopComponents>> => {
    const ViewPage = module.default;

    return (props) => {
      useEffect(() => {
        import('../../pages/VCP/desktop/view');
        import('../../pages/hub/desktop/view');
      }, []);

      return <ViewPage {...props} />;
    };
  };

  export const errorPageContainer =
    (error: unknown): FC =>
    () =>
      <ErrorPageContainer error={error} />;
}
