import { buildRoutes } from 'frontend-spa';

import { HubFilterRoutes, HubRoute } from '../../../../pages/hub/desktop/route';
import { VCPRoutes } from '../../../../pages/VCP/desktop/route';
import { SettingsRoutes } from '../../../../pages/settings/desktop/route';
import { ParentalControlRoutes } from '../../../../pages/parental-control/desktop/route';
import { SearchResultRoutes } from '../../../../pages/search-results/desktop/route';
import { CollectionsRoute } from '../../../../pages/collections/desktop/route';

export default buildRoutes([
  ...HubFilterRoutes,
  ...SettingsRoutes,
  ...ParentalControlRoutes,
  ...VCPRoutes,
  ...SearchResultRoutes,
  ...CollectionsRoute,
  HubRoute,
]);
