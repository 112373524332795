import type { Route } from 'frontend-spa';

import pages from '../../../constants/pages.json';
import api from '../../../constants/api.json';
import { spaRoute } from '../../../common/spa-route';
import { Pages } from '../../../utils/utils/pages';
import { restClient } from '../../../common/rest-client';
import { QueryParams } from '../../../utils/query-params';
import { Utils } from '../../../utils';

const { PATH_API } = api;
const { COLLECTIONS_PAGE_LANG } = pages;

const baseRoute: Omit<Route, 'path'> = spaRoute({
  exact: true,
  async resolve() {
    return import(/* webpackChunkName: "collections-desktop.chunk" */ './view')
      .then(Pages.preloadHubAndVcpPages)
      .catch(Pages.errorPageContainer);
  },
  webpack() {
    return require.resolveWeak('./view');
  },
  async fetchData(params) {
    const { data } = await restClient.get(
      `/${PATH_API.COLLECTIONS}/${params.collection_id}`,
      {
        singletonId: 'spa-navigation',
        params: {
          ...QueryParams.filterParamsWithCustomPrefix(window.location.href),
        },
      },
    );

    return data;
  },
});

export const CollectionsRoute: Array<Route> = Utils.getArrayValuesFromObject({
  ...COLLECTIONS_PAGE_LANG,
}).map((path) => ({
  path: `${path}/:collection_id`,
  ...baseRoute,
}));
