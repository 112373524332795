import type { Route } from 'frontend-spa';

import pages from '../../../constants/pages.json';
import { Utils } from '../../../utils';
import { restClient } from '../../../common/rest-client';
import api from '../../../constants/api.json';
import { ErrorPageContainer } from '../../../container/ErrorContainer/ErrorPageContainer';
import { QueryParams } from '../../../utils/query-params';
import { spaRoute } from '../../../common/spa-route';

const { PARENTAL_CONTROL_PAGE_LANG, PARENTAL_CONTROL_PAGE } = pages;
const { PATH_API } = api;

export const ParentalControlRoutes: Array<Route> =
  Utils.getArrayValuesFromObject({
    oldRoute: PARENTAL_CONTROL_PAGE,
    ...PARENTAL_CONTROL_PAGE_LANG,
  }).map((path) =>
    spaRoute({
      path,
      exact: true,
      async resolve() {
        return import(
          /* webpackChunkName: "parental-control-desktop.chunk" */ './view'
        )
          .then((module) => module.default)
          .catch((error: unknown) => () => (
            <ErrorPageContainer error={error} />
          ));
      },
      webpack() {
        return require.resolveWeak('./view');
      },
      async fetchData() {
        const { data } = await restClient.get(`/${PATH_API.PARENTAL_CONTROL}`, {
          singletonId: 'spa-navigation',
          params: QueryParams.filterParamsWithCustomPrefix(
            window.location.href,
          ),
        });

        return data;
      },
    }),
  );
