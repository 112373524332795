import type { FC } from 'react';
import type { LinkButtonProps } from './LinkButton.types';

import { AndesLinkButton } from './components/AndesLinkButton';
import { Link } from '../../../../Link';

export const LinkButton: FC<LinkButtonProps> = ({
  label,
  leftIcon,
  ...props
}) => (
  <Link component={AndesLinkButton} {...props}>
    {leftIcon} {label}
  </Link>
);
