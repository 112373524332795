import type { ComponentType } from 'react';
import type { PageProps } from '../../../components/Page';

import { useEffect, useState } from 'react';

import { getErrorView } from './get-error-view';
import { isView } from './is-view';

type ResolveType = () => Promise<ComponentType<PageProps>>;

export const partialViewResolve = (resolve: ResolveType) => async () =>
  resolve()
    .then((Component) => ({ view, ...props }: PageProps) => {
      const [loadedView, setLoadedView] = useState(view);

      useEffect(() => {
        // Puede llegar como undefined cuando no queremos actualizar la vista
        // después de cancelar un fetchData "bloqueante" (por ej al navegar muy
        // rápido entre VCPs sin preload)
        if (view) {
          setLoadedView(view);
        }
      }, [view]);

      useEffect(() => {
        loadedView.viewPromise
          ?.then(setLoadedView)
          .catch((error) => {
            const errorView = getErrorView(error);

            if (isView(errorView)) {
              setLoadedView(errorView);
            }
          })
          .catch(() => {
            /* empty */
          });
      }, [loadedView]);

      return <Component view={loadedView} {...props} />;
    })
    .catch(() => {
      /* empty */
    });
