import axios from 'nordic-axios';

import {
  CustomApiError,
  ErrorType,
} from '../../../../api/middlewares/error-api-middleware/custom-error-api';

export const getErrorView = (error: unknown) => {
  if (axios.isCancel(error)) {
    return undefined;
  }

  const customError = new CustomApiError(error, ErrorType.PAGE);

  return customError.getApiError('desktop');
};
