import type { KnownComponent } from '../../../types/components';
import type { DesktopComponents } from '../../components/Page';
import type { SnackbarProps } from '../../components/Snackbar';

import { TypeKnownComponent } from '../../../types/components';

export const transformDesktopComponents = (
  components: Array<KnownComponent>,
): DesktopComponents => {
  const result: DesktopComponents = {};

  return components.reduce(
    (currentValue, { id, type, props, ...otherProps }) => {
      if (type === TypeKnownComponent.SNACKBAR) {
        const snackbarList = currentValue[TypeKnownComponent.SNACKBAR] ?? [];
        const newSnackbar: SnackbarProps = { id, ...props };

        snackbarList.push(newSnackbar);

        return {
          ...currentValue,
          [TypeKnownComponent.SNACKBAR]: snackbarList,
        };
      }

      return {
        ...currentValue,
        [id ?? type]: { ...props, ...otherProps },
      };
    },
    result,
  );
};
