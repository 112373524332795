import type { ButtonGoToProps } from './ButtonGoTo.types';

import { useContext } from 'react';

import { i18nContext } from '../../../../context';
import { Button } from '../../../../components/Button/mobile';

export const ButtonGoTo = ({ label = '', ...props }: ButtonGoToProps) => {
  const { get } = useContext(i18nContext) || props.i18nProps;

  return <Button label={get(label)} {...props} />;
};
