import type { Route } from 'frontend-spa';

import { Utils } from '../../../utils';
import pages from '../../../constants/pages.json';
import { restClient } from '../../../common/rest-client';
import api from '../../../constants/api.json';
import { ErrorPageContainer } from '../../../container/ErrorContainer/ErrorPageContainer';
import { QueryParams } from '../../../utils/query-params';
import { spaRoute } from '../../../common/spa-route';

const { SETTINGS_PAGE_LANG, SETTINGS_PAGE } = pages;
const { PATH_API } = api;

export const SettingsRoutes: Array<Route> = Utils.getArrayValuesFromObject({
  oldRoute: SETTINGS_PAGE,
  ...SETTINGS_PAGE_LANG,
}).map((path) =>
  spaRoute({
    path,
    exact: true,
    async resolve() {
      return import(/* webpackChunkName: "settings-desktop.chunk" */ './view')
        .then((module) => module.default)
        .catch((error: unknown) => () => <ErrorPageContainer error={error} />);
    },
    webpack() {
      return require.resolveWeak('./view');
    },
    async fetchData() {
      const { data } = await restClient.get(`/${PATH_API.SETTINGS}`, {
        singletonId: 'spa-navigation',
        params: QueryParams.filterParamsWithCustomPrefix(window.location.href),
      });

      return data;
    },
  }),
);
