import type { Route } from 'frontend-spa';

import api from '../../../constants/api.json';
import pages from '../../../constants/pages.json';
import { Utils } from '../../../utils';
import { restClient } from '../../../common/rest-client';
import { Pages } from '../../../utils/utils/pages';
import { QueryParams } from '../../../utils/query-params';
import { spaRoute } from '../../../common/spa-route';

const { PATH_API } = api;
const { HUB_PAGE_FILTER_LANG } = pages;

const baseRoute: Omit<Route, 'path'> = spaRoute({
  exact: true,
  async resolve() {
    return import(/* webpackChunkName: "hub-desktop.chunk" */ './view')
      .then(Pages.preloadHubAndVcpPages)
      .catch(Pages.errorPageContainer);
  },
  webpack() {
    return require.resolveWeak('./view');
  },
  async fetchData(params) {
    const { data } = await restClient.get(`/${PATH_API.HUB}`, {
      singletonId: 'spa-navigation',
      params: {
        filter: params.subfilter
          ? `${params.filter}/${params.subfilter}`
          : params.filter,
        ...QueryParams.filterParamsWithCustomPrefix(window.location.href),
      },
    });

    return data;
  },
});

export const HubRoute: Route = {
  path: '/',
  ...baseRoute,
};

export const HubFilterRoutes: Array<Route> = Utils.getArrayValuesFromObject({
  ...HUB_PAGE_FILTER_LANG,
}).map((path) => ({
  path: `${path}/:filter/:subfilter?`,
  ...baseRoute,
}));
