/* eslint-disable @typescript-eslint/no-explicit-any */
import snakecaseKeys from 'snakecase-keys';

import queryParams from '../../constants/query-params.json';

export namespace Utils {
  export const getPercentage = (
    value: number,
    maxValue: number,
    base = 100,
  ): number => Math.round((value * base) / maxValue);

  export const getArrayValuesFromObject = (
    object: Record<string, string>,
  ): Array<string> =>
    Array.from(new Set(Object.entries(object).map(([, value]) => value)));

  export const get = (
    object: Record<string, any>,
    path: string,
    defaultValue = '',
  ): string => {
    const pathArray = path.split('.');
    const result = pathArray.reduce(
      (acc, key) => (acc ? acc[key] : undefined),
      object,
    );

    return result !== undefined ? String(result) : defaultValue;
  };

  export const transformCamelCaseToSnakeCase = (
    input: Record<string, string>,
  ) => snakecaseKeys(input);

  export const getFilteredSearchParams = (searchParams: URLSearchParams) => {
    const requestedQueryParams = queryParams.map(({ name }) => name);
    const filteredParams: Record<string, string> = {};

    searchParams.forEach((_value, key) => {
      if (!requestedQueryParams.some((findKey) => key.startsWith(findKey))) {
        const value = searchParams.get(key);

        if (value !== null) {
          filteredParams[String(key)] = value;
        }
      }
    });

    return new URLSearchParams(filteredParams).toString();
  };
}
