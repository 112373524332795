import type { Request } from 'express';
import type { ParsedQs } from 'qs';

import pick from 'lodash/pick';
import endsWith from 'lodash/endsWith';

import queryParams from '../../constants/query-params.json';
import { validateDTO } from '../dto-validator';

type SchemaProperties = {
  type: string;
  pattern: string;
  nullable: boolean;
};

export namespace QueryParams {
  const filterValuesFromObject = (
    value: ParsedQs,
    pickValues: Array<string>,
  ): Array<
    [string, Array<ParsedQs> | Array<string> | ParsedQs | string | undefined]
  > =>
    Object.entries(value).filter(([key]) =>
      pickValues.some((findKey) => key.startsWith(findKey)),
    );

  export const filterParamsWithCustomPrefix = (url: string) => {
    const { search } = new URL(url);
    const searchParams = new URLSearchParams(search);
    const requestedQueryParams = queryParams.map(({ name }) => name);
    const newSearchParams: Record<string, string> = {};

    searchParams.forEach((value: string, key: string) => {
      newSearchParams[key] = value;
    });

    const filtered = filterValuesFromObject(
      newSearchParams,
      requestedQueryParams,
    );

    return filtered.reduce<ParsedQs>(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {},
    );
  };

  const generateDynamicSchema = () => {
    const properties = queryParams.reduce<{
      properties: Record<string, SchemaProperties>;
      patternProperties: Record<string, SchemaProperties>;
    }>(
      (acc, currentValue) => {
        if (endsWith(currentValue.name, '_')) {
          acc.patternProperties[`^${currentValue.name}`] = {
            type: currentValue.type,
            pattern: currentValue.pattern,
            nullable: false,
          };
        } else {
          acc.properties[currentValue.name] = {
            type: currentValue.type,
            pattern: currentValue.pattern,
            nullable: false,
          };
        }

        return { ...acc };
      },
      {
        properties: {},
        patternProperties: {},
      },
    );

    return {
      type: 'object',
      additionalProperties: true,
      ...properties,
    };
  };

  export const getRequestWithValidatedQueryParams = (
    req: Request,
  ): ParsedQs => {
    const reqQueryParams = req.query;
    const listValidQueryParams = queryParams.map(({ name }) => name);
    const filtered = filterValuesFromObject(
      reqQueryParams,
      listValidQueryParams,
    ).map(([key]) => key);
    const pickedQueryParams = pick(reqQueryParams, filtered);
    const queryParamsSchemaDto = generateDynamicSchema();
    const validDto = validateDTO(queryParamsSchemaDto, pickedQueryParams);

    return validDto ? pickedQueryParams : {};
  };
}
