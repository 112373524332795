import './app.styles.scss';

import type { Translations } from 'nordic/i18n';

import { hydrateRoot } from 'nordic/hydrate';
import { SPAContainer, preloadReady } from 'frontend-spa';
import { I18n, I18nProvider } from 'nordic/i18n';

import routes from './routes';

const state = window.__PRELOADED_STATE__;

const i18n = new I18n({
  translations: state.translations as Translations,
});

preloadReady()
  .then(() =>
    hydrateRoot(
      document.getElementById('root-app')!,
      <I18nProvider i18n={i18n}>
        <SPAContainer routes={routes} {...state} />
      </I18nProvider>,
    ),
  )
  .catch(() => {
    /* empty */
  });
