import type { Route } from 'frontend-spa';

import { partialViewFetchData } from './utils/partial-view-fetch-data';
import { partialViewResolve } from './utils/partial-view-resolve';

export const spaRoute = (route: Route): Route => ({
  ...route,
  resolve: partialViewResolve(route.resolve),
  fetchData: partialViewFetchData(route.fetchData),
});
