/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  KnownComponent,
  KnownComponentsMap,
} from '../../../../types/components';
import type {
  DesktopComponents,
  MobileComponents,
  ViewProps,
} from '../../../components/Page';

import unknownError from '../../../../services/utils/custom-error/known-errors/unknown-error.json';
import { ErrorCode, ErrorType } from './custom-error.pages.types';

export const mapErrors = new Map<ErrorCode, ViewProps>([
  [ErrorCode.UNKNOWN_ERROR, unknownError as ViewProps],
]);

export class CustomErrorPages extends Error {
  name = 'CustomErrorPages';

  error: unknown;

  errorCode: ErrorCode = ErrorCode.UNKNOWN_ERROR;

  constructor(error: unknown) {
    super();
    this.error = error;
    this.initError(error);
  }

  private initError(error: unknown) {
    if (this.isChunkProblemLoad(error)) {
      this.errorCode = ErrorCode.UNKNOWN_ERROR;
    }
  }

  private isChunkProblemLoad(error: unknown) {
    if ((error as any)?.name === ErrorType.CHUNK_LOAD_ERROR) {
      return true;
    }

    return false;
  }

  public getError() {
    return this.error;
  }

  public transformDesktopComponents(
    components: Array<KnownComponent> = [],
  ): Partial<KnownComponentsMap> {
    return components.reduce(
      (currentValue, { type, props, ...otherProps }) => ({
        ...currentValue,
        [type]: { ...props, ...otherProps },
      }),
      {},
    );
  }

  public getComponentsPageError<
    T extends DesktopComponents | MobileComponents = MobileComponents,
  >(device = 'mobile'): ViewProps<T> {
    const errorMap = mapErrors.get(this.errorCode);

    return device === 'mobile'
      ? (errorMap as ViewProps<T>)
      : ({
          ...errorMap,
          components: this.transformDesktopComponents(errorMap?.components),
        } as ViewProps<T>);
  }
}
